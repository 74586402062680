import { useState } from "react";

const AmountSelectionButtons = ({
  isSelectionInUse,
  setSelectionInUse,
  setAmount,
  tonPriceInUsd,
  currency,
}) => {
  const amounts = ["5.00", "10.00", "20.00", "50.00"];
  const [selectedIndex, setSelectedIndex] = useState(null);

  return (
    <div className="flex flex-row gap-1.5 text-sm">
      {amounts.map((value, index) => (
        <div className="w-1/4">
          {index === selectedIndex && isSelectionInUse ? (
            <button
              key={index}
              className="bg-[#21127B] text-white rounded-lg h-12 flex justify-center items-center w-full"
              style={{
                background: "rgb(34, 20, 135)",
              }}
            >
              ${value}
            </button>
          ) : (
            <button
              key={index}
              className="text-white rounded-lg h-12 flex justify-center items-center w-full"
              style={{
                background: "rgba(33, 18, 123, 0.45)",
              }}
              onClick={() => {
                setSelectionInUse(true);
                setSelectedIndex(index);
                if (currency === "TON") {
                  setAmount((value / tonPriceInUsd).toFixed(9));
                }
                if (currency === "Dollars") {
                  setAmount(Number(value).toFixed(2));
                }
              }}
            >
              <div className="opacity-30">${value}</div>
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default AmountSelectionButtons;
