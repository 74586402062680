import rollButtonImage from "../../assets/images/dice/roll_button.png";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const RollButton = ({ onClick }) => {
  const { isLocked, isRolling } = useSelector((state) => state.dice);

  const isSpinDisabled = useMemo(() => {
    return isRolling || isLocked;
  }, [isRolling, isLocked]);

  return (
    <button
      onClick={onClick}
      className="aspect-[585/212] w-[60%]"
      disabled={isSpinDisabled}
      style={{
        filter: isSpinDisabled ? "grayscale(100%)" : null,
      }}
    >
      <img src={rollButtonImage} />
    </button>
  );
};

export default RollButton;
