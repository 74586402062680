import { useState } from "react";
import arrowDownIcon from "../assets/images/arrow_down_icon.svg";

const FeeInformation = ({ text, isRecommended }) => {
  return isRecommended ? (
    <div className="pr-1 gradient-text-green text-xs">
      (Recommended, {text})
    </div>
  ) : (
    text && <div className="pr-1 opacity-35 text-xs">({text})</div>
  );
};

const DropdownMenu = ({
  items,
  label,
  icons,
  selectedIndex,
  setSelectedIndex,
  isCentered = false,
  recommendedIndex,
  feeTexts,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="relative">
      {typeof(label) === 'string' ? (
        <div className="text-xs mb-[0.5rem] opacity-35">{label}</div>
      ) : (
        label
      )}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex flex-row h-14 w-full justify-between items-center rounded-xl mt-[0.5rem] px-3 w-35"
        style={{
          background: "rgba(66, 43, 210, 0.45)",
        }}
      >
        <div className="font-medium text-sm self-center text-center w-30">
          <div className="flex flex-row gap-3 items-center">
            <div className="w-9">{icons[selectedIndex]}</div>
            {items[selectedIndex]}
          </div>
        </div>
        <div className="flex flex-row items-center">
          <div className="pr-1">
            <FeeInformation
              text={feeTexts && feeTexts[selectedIndex]}
              isRecommended={selectedIndex === recommendedIndex}
            />
          </div>
          <img
            src={arrowDownIcon}
            alt="arrow down icon"
            className="w-4 h-3 ml-2"
          />
        </div>
      </button>
      {isOpen && (
        <div
          style={{
            background: "rgb(34, 20, 135)",
          }}
          className="absolute w-full bg-white pl-3 rounded-xl mt-1 z-[2] py-1"
        >
          {items.map((text, index) => (
            <button
              key={index}
              onClick={() => {
                setSelectedIndex(index);
                setIsOpen(false);
              }}
              className={`w-full flex h-8 ${
                isCentered ? "justify-center" : "justify-start"
              } items-center my-1.5 text-sm`}
            >
              <div className="flex justify-between items-center w-full">
                <div className="flex flex-row gap-3 items-center">
                  <div className="w-9">{icons[index]}</div>
                  {text}
                </div>
                <div className="pr-10">
                  <FeeInformation
                    text={feeTexts && feeTexts[index]}
                    isRecommended={index === recommendedIndex}
                  />
                </div>
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
