import { CHAIN, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { useSelector } from 'react-redux';
import TonWeb from 'tonweb';
import { TON_TESTNET, tonNetwork } from '../constants';

function useSendDeposit() {
    const sendTonDeposit = useSendTonDeposit();
    const sendTonUsdtDeposit = useSendTonUsdtDeposit();

    return async (amount, address, currency) => {
        if (currency === "TON") {
            await sendTonDeposit(amount, address);
        } else if (currency === "Dollars") {
            await sendTonUsdtDeposit(amount, address);
        }
    };
}

function useSendTonDeposit() {
    const [tonConnect] = useTonConnectUI();
    const { id: userId } = useSelector((state) => state.user);
    const fromAddress = useTonAddress();
    return async (amount, address) => {
        const transaction = {
            validUntil: Math.floor(Date.now() / 1000) + 360,
            from: fromAddress,
            network: tonNetwork === TON_TESTNET ? CHAIN.TESTNET : CHAIN.MAINNET,
            messages: [
                {
                    address,
                    amount: amount.toString(),
                    payload: await guidToBase64(userId),
                },
            ],
        };

        try {
            await tonConnect.sendTransaction(transaction);
        } catch (e) {
            console.log(e);
        }
    };
}

function isValidGuid(guid) {
    const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return guidRegex.test(guid);
}

function guidToBytes(guid) {
    if (!isValidGuid(guid)) {
        throw new Error("Invalid GUID format");
    }

    const hex = guid.replace(/-/g, '');

    return new Uint8Array(hex.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
}

function guidToCell(guid) {
    const bytes = guidToBytes(guid);
    const cell = new TonWeb.boc.Cell();
    cell.bits.writeUint(0, 32);
    cell.bits.writeBytes(bytes);

    return cell;
}

async function guidToBase64(guid) {
    const cell = guidToCell(guid);

    return TonWeb.utils.bytesToBase64(await cell.toBoc());
}

function useSendTonUsdtDeposit() {
    const [tonConnect] = useTonConnectUI();
    const { id: userId } = useSelector((state) => state.user);
    const fromAddress = useTonAddress();
    return async (amount, address) => {
        const jettonMaster = getUsdtJettonMaster();
        const recipientAddress = new TonWeb.utils.Address(address);
        const senderJettonWalletAddress = await jettonMaster.getJettonWalletAddress(new TonWeb.utils.Address(fromAddress));

        try {
            const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 360,
                from: fromAddress,
                network: tonNetwork === TON_TESTNET ? CHAIN.TESTNET : CHAIN.MAINNET,
                messages: [
                    {
                        address: senderJettonWalletAddress.toString(true, true, true, false),
                        amount: TonWeb.utils.toNano("0.11").toString(), // Minimal fee amount
                        payload: await getUsdtTransferPayload({ amount, recipientAddress, senderJettonWalletAddress, userId }),
                    },
                ],
            };
            await tonConnect.sendTransaction(transaction);
        } catch (e) {
            console.log(e);
        }
    }
};

function getUsdtJettonMaster() {
    const USDT_JETTON_MASTER_ADDRESS = tonNetwork === TON_TESTNET ?
        'kQDts6S1MqP8EP5wQ_K60Vg2zGndfptdVzQOt2lzMzCtuUF-' :
        'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs';
    const providerAddress = tonNetwork === TON_TESTNET ?
        'https://testnet.toncenter.com/api/v2/jsonRPC' :
        'https://toncenter.com/api/v2/jsonRPC';
    const tonweb = new TonWeb(new TonWeb.HttpProvider(providerAddress));
    return new TonWeb.token.jetton.JettonMinter(tonweb.provider, { address: new TonWeb.utils.Address(USDT_JETTON_MASTER_ADDRESS) });
}

async function getUsdtTransferPayload({ amount, recipientAddress, senderJettonWalletAddress, userId }) {
    const body = new TonWeb.boc.Cell();
    body.bits.writeUint(0xf8a7ea5, 32); // jetton transfer op code
    body.bits.writeUint(0, 64); // query_id:uint64
    body.bits.writeCoins(amount.toString()); // amount:(VarUInteger 16)
    body.bits.writeAddress(recipientAddress); // destination:MsgAddress
    body.bits.writeAddress(senderJettonWalletAddress); // response_destination:MsgAddress
    body.bits.writeUint(0, 1); // custom_payload:(Maybe ^Cell)
    body.bits.writeCoins(TonWeb.utils.toNano("0.01")); // forward_ton_amount:(VarUInteger 16)
    body.bits.writeBit(1);

    const forwardPayload = new TonWeb.boc.Cell();
    forwardPayload.bits.writeBytes(guidToBytes(userId)); // Attach user ID
    body.refs.push(forwardPayload); // Attach forward payload

    return TonWeb.utils.bytesToBase64(await body.toBoc());
}

export default useSendDeposit;