import { useMemo } from "react";
import ProgressBar from "./ProgressBar";
import AirdropCoinIcon from "./AirdropCoinIcon";
import TickIcon from "./TickIcon";
import { Badge } from "./Badge";
import SquareIconButton from "./SquareIconButton";
import { openLink } from "../utils/socialMedia";
import linkIcon from "../assets/images/link_icon.svg";
import { Tooltip } from "react-tooltip";

const TrailingTaskButton = ({
  reward,
  tooltipId,
  onClick,
  isRewardInCC,
  isCompleted,
  progress,
  secretCode,
}) => {
  if (!isCompleted || progress) {
    return (
      <>
        {secretCode && <Tooltip id={tooltipId}>{secretCode}</Tooltip>}
        <div data-tooltip-id={tooltipId} data-tooltip-place="bottom">
          <Badge
            icon={isRewardInCC && <AirdropCoinIcon className={"w-5 h-5 m-2"} />}
            text={reward}
            isDisabled={!secretCode && progress && !isCompleted}
            onClick={onClick}
          />
        </div>
      </>
    );
  }

  return (
    <Badge
      icon={
        isRewardInCC && (
          <div className="m-2">
            <TickIcon color={"#1E0F6A"} size={"1.25rem"} />
          </div>
        )
      }
      text={reward}
    />
  );
};

const TaskCard = ({
  isRewardInCC = true,
  tooltipId,
  icon,
  title,
  reward,
  progress,
  progressUnit,
  buttonText,
  secretCode,
  iconClassName,
  link,
  onButtonClick,
  isCompleted = false,
}) => {
  const progressSpecificStyling = useMemo(() => {
    if (progress === undefined || progress === null) {
      return "h-12 justify-center";
    }
    if (buttonText === undefined || buttonText === null) {
      return "min-h-16 justify-between";
    }
  }, [progress, buttonText]);

  return (
    <div className="flex flex-col flex-grow justify-start mx-5 mt-3">
      {progress && (
        <div className="text-xs mb-[0.5rem] opacity-35">
          {title} {progressUnit ?? ""}
          {progress.unitsCompleted}/{progressUnit ?? ""}
          {progress.goal}
        </div>
      )}
      <div
        style={{
          background: "rgba(66, 43, 210, 0.45)",
          borderColor: "rgba(66, 43, 210, 0.45)",
        }}
        className={`flex flex-col flex-grow border rounded-xl ${progressSpecificStyling} py-2 pl-4`}
      >
        <div className="flex flex-row justify-between items-center mr-1.5">
          <div className="flex flex-row items-center">
            <img
              src={icon}
              alt="Task icon"
              className={`inline-block mr-2 ${iconClassName || "w-7 h-7"}`}
            />
            {!progress && <div className="text-sm ml-2">{title}</div>}
          </div>
          {progress && (
            <div className="flex-grow ml-2 mr-4">
              <ProgressBar
                progress={
                  progress.goal === 0 ||
                  progress.goal === null ||
                  progress.goal === undefined
                    ? 0
                    : progress.unitsCompleted / progress.goal
                }
              />
            </div>
          )}
          <div className="flex flex-row items-center gap-2">
            <TrailingTaskButton
              text={buttonText}
              tooltipId={tooltipId}
              onClick={onButtonClick}
              isRewardInCC={isRewardInCC}
              isCompleted={isCompleted}
              secretCode={secretCode}
              reward={reward}
              progress={progress}
            />
            {link && (
              <SquareIconButton
                onClick={() => {
                  openLink(link);
                }}
                icon={linkIcon}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskCard;
