const InputField = ({
  label,
  errorMessage,
  placeholder,
  className,
  value,
  onChange,
  type,
  inputMode,
  pattern,
  step,
  isDisabled = false,
  icon,
  trailingText,
  trailingTextSize = "text-xs",
}) => {
  return (
    <div
      className="w-full"
      style={{
        filter: isDisabled ? "grayscale(1)" : null,
      }}
    >
      <div className="text-xs mb-[0.5rem] opacity-35">{label}</div>
      <div
        className={`rounded-xl border-2 overflow-hidden mt-[0.5rem] ${className}`}
        style={{
          borderColor: "rgba(66, 43, 210, 0.75)",
          backgroundColor: "rgba(33, 18, 123, 0.45)",
        }}
      >
        <div className="flex flex-row items-center">
          {icon && icon}
          <input
            disabled={isDisabled}
            step={step}
            type={type}
            inputMode={inputMode}
            pattern={pattern}
            className={`h-14 p-3 w-full text-sm`}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            style={{
              background: "transparent",
            }}
          ></input>
          {trailingText && (
            <>
              <div
                className="h-7 rounded-lg bg-[#21127B] mr-3"
                style={{
                  borderColor: "rgba(66, 43, 210, 0.75)",
                  borderWidth: "0.93px",
                  width: "2px",
                }}
              ></div>
              <div
                className={`${trailingTextSize} opacity-30 text-nowrap text-center pr-3 min-w-fit`}
              >
                {trailingText}
              </div>
            </>
          )}
        </div>
      </div>
      {errorMessage && (
        <div className="text-red-500 text-xs mt-1 absolute">{errorMessage}</div>
      )}
    </div>
  );
};
export default InputField;
