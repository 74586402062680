import IconTabBar from "../../components/IconTabBar";
import crownIcon from "../../assets/images/crown_icon.svg";
import personIcon from "../../assets/images/person_icon.svg";
import CasinoCoinsTabContent from "./CasinoCoinsTabContent";
import CommissionSummaryTabContent from "./CommissionSummaryTabContent";
import { ReferredPeopleTabContent } from "./ReferredPeopleTabContent";
import TopReferrersTabContent from "./TopReferrersTabContent";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedReferralTabIndex } from "../../features/generalSlice";

const ReferralScreen = () => {
  const dispatch = useDispatch();
  const { selectedReferralTabIndex } = useSelector((state) => state.general);
  return (
    <div className="mt-24 px-5 h-full">
      <IconTabBar
        leftIcon={<div className="agbalumo-regular text-xl">CC</div>}
        middleLeftIcon={<div className="agbalumo-regular text-lg">%</div>}
        middleRightIcon={
          <img src={crownIcon} className="w-6 h-6" alt="Crown icon" />
        }
        rightIcon={<img src={personIcon} alt="Person icon" />}
        selectedTabIndex={selectedReferralTabIndex}
        setSelectedTabIndex={(index) =>
          dispatch(setSelectedReferralTabIndex(index))
        }
        leftTabContent={<CasinoCoinsTabContent />}
        middleLeftTabContent={<CommissionSummaryTabContent />}
        middleRightTabContent={<TopReferrersTabContent />}
        rightTabContent={<ReferredPeopleTabContent />}
      />
    </div>
  );
};
export default ReferralScreen;
