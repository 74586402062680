const Button = ({
  text,
  isDisabled = false,
  onClick,
  icon,
  variant = "primary",
}) => {
  const variantStyling = {
    primary: "h-12 w-full px-4 text-xl font-bold rounded-lg text-[#1E0F6A]",
    secondary:
      "h-[calc(2rem+3px)] px-4 text-md font-bold rounded-lg mb-[3px] text-[#1E0F6A]",
    third: "h-8 w-full px-4 text-xs font-bold rounded-lg text-[#1E0F6A]",
    fourth: "h-12 w-full px-4 text-xl font-bold rounded-lg text-white", // Match primary's styling
    fifth: "h-8 w-full px-4 text-xs font-bold rounded-lg text-white", // Match primary's styling
  };

  const variantBackground = {
    primary: "linear-gradient(to bottom, #15CF05, #91F934)",
    secondary: "linear-gradient(to bottom, #15CF05, #91F934)",
    third: "linear-gradient(to bottom, #617BFF, #F5F7FF)",
    fourth: "linear-gradient(to bottom, #0088CC, #005A9C)", // Telegram's blue gradient
    fifth: "linear-gradient(to bottom, #0088CC, #005A9C)", // Telegram's blue gradient
  };

  const variantBoxShadow = {
    primary: "0px 5px 0px #46CC1C, inset 0px -3px 0px #C3FD70",
    secondary: "0px 3px 0px #46CC1C", // No inset shadow for secondary, if needed
    third: "0px 1px 0px #FFFFFF, 0px 4px 0px #8FA2FF", // Combine the box shadows
    fourth: "0px 5px 0px #004880, inset 0px -3px 0px #24799e", // Telegram's shadow colors
    fifth: "0px 1px 0px #004880, 0px 4px 0px #24799e", // Telegram's shadow colors
  };

  const variantIconStyling = {
    primary: "w-4 h-4 mr-4",
    secondary: "w-4 h-4 mr-4",
    third: "w-4 h-4 mr-4",
    fourth: "w-7 h-7 absolute left-3",
    fifth: "w-6 h-6 absolute left-2",
  };

  return (
    <div
      style={{
        filter: isDisabled ? "grayscale(1)" : "none",
      }}
      className="w-full"
    >
      <button
        className={`flex flex-row items-center justify-center relative mb-[5px] ${variantStyling[variant]}`}
        style={{
          background: variantBackground[variant],
          boxShadow: variantBoxShadow[variant], // Use box-shadow instead of filter
        }}
        disabled={isDisabled}
        onClick={!isDisabled ? onClick : () => {}}
      >
        {icon && (
          <img src={icon} alt="icon" className={variantIconStyling[variant]} />
        )}
        <div
          className=" overflow-hidden whitespace-nowrap text-ellipsis"
          style={{
            opacity: isDisabled ? 0.35 : 1,
          }}
        >
          {text}
        </div>
      </button>
    </div>
  );
};

export default Button;
