import balanceIndicatorFieldImage from "../../assets/images/dice/balance_indicator_field.png";
import balanceIndicatorFieldOverlay from "../../assets/images/dice/balance_indicator_field_overlay.png";

const BalanceIndicator = ({ coinIcon, idToPass, className }) => {
  return (
    <div
      className="relative rounded-full h-12 flex flex-row items-center bg-no-repeat bg-center bg-cover aspect-[423/145] mb-[-1.5rem] pr-5"
      style={{
        backgroundImage: `url(${balanceIndicatorFieldImage})`,
      }}
    >
      {/* Overlay covers the entire parent */}
      <img
        src={balanceIndicatorFieldOverlay}
        className="absolute top-5 w-full h-full object-cover"
        style={{ mixBlendMode: "overlay" }}
        alt="Balance Overlay"
      />

      {/* Left column: fixed-width container for the icon. */}
      <div className="relative flex items-center justify-center w-12 h-full">
        <img src={coinIcon} alt="Coin Icon" className={className} />
      </div>

      {/* Right column (remaining space): center the text horizontally & vertically */}
      <div className="flex-1 flex items-center justify-center">
        <div
          id={idToPass}
          className="alien-encounters text-lg font-bold opacity-80 text-center z-10 mt-1"
        />
      </div>
    </div>
  );
};

export default BalanceIndicator;
