import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import "./SlotMachineReel.css";
import { reelSymbols } from "../../utils/reelSymbols";

const nameToImage = {
  BTC: require("../../assets/images/slot_machine_symbols/bitcoin.png"),
  ETH: require("../../assets/images/slot_machine_symbols/eth.png"),
  BNB: require("../../assets/images/slot_machine_symbols/bnb.png"),
  Doge: require("../../assets/images/slot_machine_symbols/doge.png"),
  XRP: require("../../assets/images/slot_machine_symbols/xrp.png"),
  Cardano: require("../../assets/images/slot_machine_symbols/cardano.png"),
  Shiba: require("../../assets/images/slot_machine_symbols/shiba.png"),
  CRO: require("../../assets/images/slot_machine_symbols/cronos.png"),
  Monero: require("../../assets/images/slot_machine_symbols/monero.png"),
  Pepe: require("../../assets/images/slot_machine_symbols/pepe.png"),
  Uniswap: require("../../assets/images/slot_machine_symbols/uniswap.png"),
};

const SlotMachineReel = forwardRef(({ isLast }, ref) => {
  const [isSpinning, setSpinning] = useState(false);
  const [highlightedPosition, setHighlightedPosition] = useState(null);
  const reelRef = useRef(null);
  const images = useMemo(
    () => reelSymbols.map((symbol) => nameToImage[symbol]),
    []
  );
  const symbols = useMemo(() => [...images, ...images, ...images], [images]);
  const symbolHeight = 17.8;
  const reelHeight = 3 * symbolHeight;

  useEffect(() => {
    const preloadImages = async () => {
      const promises = symbols.map((symbol) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = symbol;
          img.onload = resolve;
          img.onerror = resolve;
        });
      });
      await Promise.all(promises);
    };

    preloadImages();
  }, [symbols]);

  useEffect(() => {
    if (!reelRef.current) return;
    const scrollHeight = reelRef.current.scrollHeight;
    const scrollHeightVw = (scrollHeight / window.innerWidth) * 100;
    reelRef.current.style.transform = `translateY(${
      scrollHeightVw - reelHeight - images.length * symbolHeight
    }vw)`;
  }, [symbols, images.length, reelHeight]);

  const startSpinning = (finalPosition) => {
    if (isSpinning || !reelRef.current) return;
    setHighlightedPosition(null);
    setSpinning(true);

    const n = images.length;
    const totalPositions = images.length * 2;

    const scrollHeight = reelRef.current.scrollHeight;
    const scrollHeightVw = (scrollHeight / window.innerWidth) * 100;

    const style = window.getComputedStyle(reelRef.current);
    const transform = style.transform;

    let currentTranslateY = 0;
    if (transform && transform !== "none") {
      const values = transform.match(/matrix.*\((.+)\)/)[1].split(", ");
      currentTranslateY = parseFloat(values[5]);
    }

    const currentTranslateYVw = (currentTranslateY / window.innerWidth) * 100;

    let currentPosition = Math.round(
      (scrollHeightVw - reelHeight - currentTranslateYVw) / symbolHeight
    );

    currentPosition = currentPosition % totalPositions;
    if (currentPosition < 0) currentPosition += totalPositions;

    const pos1 = finalPosition;
    const pos2 = (finalPosition + n) % totalPositions;

    const targetPositions = [pos1, pos2];

    const distances = targetPositions.map((pos) =>
      Math.abs(pos - currentPosition)
    );

    let targetPosition;
    if (distances[0] >= distances[1]) {
      targetPosition = targetPositions[0];
    } else {
      targetPosition = targetPositions[1];
    }

    const newTranslateY =
      scrollHeightVw - reelHeight - targetPosition * symbolHeight;

    reelRef.current.style.transition = "transform 4s ease-out";
    reelRef.current.style.transform = `translateY(${newTranslateY}vw)`;

    return new Promise((resolve) => {
      setTimeout(() => {
        if (!reelRef.current) return;
        reelRef.current.style.transition = "";
        reelRef.current.style.transform = `translateY(${newTranslateY}vw)`;
        setSpinning(false);
        resolve();
      }, 4000);
    });
  };

  useImperativeHandle(ref, () => ({
    spin: async (finalPosition) => {
      await startSpinning(finalPosition);
    },
    highlightSymbol: (position) => {
      setHighlightedPosition(position);
    },
    isSpinning: () => isSpinning,
  }));

  return (
    <div className="reel-container">
      <div className="reel" ref={reelRef}>
        {symbols.map((symbol, index) => (
          <img
            style={{
              ...(highlightedPosition !== null &&
              (highlightedPosition === index - 1 ||
                highlightedPosition + images.length === index - 1)
                ? {
                    animation:
                      "pulsate 0.5s infinite ease-in-out, rotate 0.5s infinite ease-in-out",
                  }
                : {}),
            }}
            key={index}
            src={symbol}
            alt="Symbol"
            className="symbol"
          />
        ))}
      </div>
    </div>
  );
});

export default SlotMachineReel;
