import { useSelector } from "react-redux";
import {
  useGetUserQuery,
  useGetLivePayoutsQuery,
  useGetPayoutHistoryQuery,
} from "../../services/official";
import WithdrawalHistoryCard from "../../components/WithdrawalHistoryCard";
import LiveWithdrawalCard from "../../components/LiveWithdrawalCard";
import { getTransactionLink } from "../../utils/blockchain";
import { useMemo } from "react";
import { dollarWorthInChips } from "../../constants";

const HistoryTabContent = () => {
  const { id } = useSelector((state) => state.user);
  const { data: user } = useGetUserQuery(id);
  const { data: liveWithdrawalData } = useGetLivePayoutsQuery();
  const payoutRequests = useMemo(
    () =>
      liveWithdrawalData?.payoutRequests && [
        ...liveWithdrawalData?.payoutRequests,
      ],
    [liveWithdrawalData?.payoutRequests]
  );

  const { data: withdrawalHistoryData } = useGetPayoutHistoryQuery({
    userId: id,
  });

  const withdrawalHistory = useMemo(
    () =>
      withdrawalHistoryData?.payoutRequests && [
        ...withdrawalHistoryData?.payoutRequests,
      ],
    [withdrawalHistoryData?.payoutRequests]
  );

  const currencies = {
    TetherErc20: "ERC-20",
    TetherTrc20: "TRC-20",
  };

  return (
    <div className="flex flex-col items-start relative pb-10">
      {payoutRequests?.length > 0 && (
        <>
          <div className="flex-grow text-xs text-right mb-[0.5rem] mt-1 opacity-35">
            Live successful withdrawals
          </div>
          <div className="gap-2 flex flex-col w-full">
            {payoutRequests
              ?.sort(
                (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
              )
              .map((payout, index) => (
                <LiveWithdrawalCard
                  index={index}
                  key={index}
                  currency={currencies[payout.currency]}
                  amount={`${(payout.chipAmount / dollarWorthInChips).toFixed(2)}`}
                  username={payout.userName}
                  date={Date.parse(payout.createdAt)}
                  transactionHash={payout.transactionHash}
                  transactionLink={
                    payout.status === "Completed" &&
                    getTransactionLink(
                      payout.currency,
                      payout.transactionHash,
                      payout.network
                    )
                  }
                />
              ))}
          </div>
          <div className="w-full h-[2px] bg-[#422BD2] opacity-25 my-5"></div>
        </>
      )}
      {withdrawalHistory?.length > 0 ? (
        <>
          <div className="flex-grow text-xs text-right mb-[0.5rem] mt-1 opacity-35">
            Your withdrawal history
          </div>
          <div className="gap-2 flex flex-col w-full">
            {withdrawalHistory
              ?.sort(
                (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
              )
              .map((payout, index) => (
                <WithdrawalHistoryCard
                  key={index}
                  currency={currencies[payout.currency]}
                  amount={`${(payout.chipAmount / dollarWorthInChips).toFixed(2)}`}
                  status={payout.status}
                  username={user.userName}
                  date={Date.parse(payout.createdAt)}
                  transactionHash={payout.transactionHash}
                  transactionLink={
                    payout.status === "Completed" &&
                    getTransactionLink(
                      payout.currency,
                      payout.transactionHash,
                      payout.network
                    )
                  }
                />
              ))}
          </div>
        </>
      ) : (
        <>
          <div className="w-full text-xs text-center mt-3 opacity-35">
            No withdrawals yet...
          </div>
        </>
      )}
    </div>
  );
};

export default HistoryTabContent;
