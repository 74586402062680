import airdropCoinIcon from "../assets/images/airdrop_coin_icon.png";

const AirdropPointsSourceCard = ({ source, amount }) => {
  return (
    <div className="w-full">
      <div
        style={{
          backgroundColor: "rgba(33, 18, 123, 0.45)",
        }}
        className="h-12 rounded-xl flex flex-col justify-between pl-1 pr-3"
      >
        <div className="flex justify-between w-full pl-3 flex-grow items-center text-sm">
          <div className="flex flex-row items-center gap-3">
            <img src={airdropCoinIcon} alt="dollar coin icon" className="h-4" />
            <div className="gap-3 flex flex-row">{amount}</div>
          </div>
          <div className="text-xs opacity-35">{source}</div>
        </div>
      </div>
    </div>
  );
};

export default AirdropPointsSourceCard;
