import React from "react";
import { Link, useLocation } from "react-router-dom";

function NavigationItem({ icon, selectedIcon, label, to }) {
  const location = useLocation();
  const isSelected =
    (to === "/" && location.pathname === "/") ||
    (to !== "/" && location.pathname.startsWith(to));

  const background = "linear-gradient(to bottom, #280EC7, #4734BD)";
  const boxShadow = `0px 2px 0px #6352CE, 0px 6px 0px #3720C3`;

  return (
    <Link to={to}>
      <div className="flex flex-col justify-center items-center">
        <div
          className="h-14 aspect-[62/55] rounded-md flex flex-col justify-between items-center"
          style={!isSelected ? {} : { background, boxShadow }}
        >
          <img
            src={icon}
            alt={`${label} icon`}
            className="object-contain self-center aspect-square h-6 z-[1] mt-3"
            style={isSelected ? { display: "none" } : {}}
          />
          <img
            src={selectedIcon}
            alt={`${label} icon`}
            className="object-contain self-center aspect-square h-6 z-[1] mt-3"
            style={!isSelected ? { display: "none" } : {}}
          />
          <div className="my-0.5 text-[10px] opacity-45">{label}</div>
        </div>
      </div>
    </Link>
  );
}

export default NavigationItem;
