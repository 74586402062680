import React from "react";
import airdropIcon from "../assets/images/airdrop_icon.png";
import notificationIcon from "../assets/images/notification_icon.png";
import AirdropCoinIcon from "./AirdropCoinIcon";
import ChipIcon from "./ChipIcon";
import { useSelector, useDispatch } from "react-redux";
import logo from "../assets/images/logo.png";
import britishFlag from "../assets/images/british_flag.svg";
import BalanceIndicator from "./BalanceIndicator";
import IconButton from "./IconButton";
import settingsIcon from "../assets/images/settings_icon.png";
import { setAirdropPopUpOpen } from "../features/generalSlice";
import DailyBonusButton from "./DailyBonusButton";
import { useNavigate, useLocation } from "react-router-dom";
import { useUserBalance } from "../utils/user";
import { useNavigateToDeposits } from "../utils/routes";
import { dollarWorthInChips } from "../constants";

function HomeHeader() {
  const isAirdropPopUpOpen = useSelector(
    (state) => state.general.isAirdropPopUpOpen
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { chipBalance, airdropBalance } = useUserBalance();
  const navigateToDeposits = useNavigateToDeposits();

  return (
    <>
      <div className="top-0 absolute z-[11] bg-[#300EA3] w-full" style={{}}>
        <div
          className="w-full max-h-fit bg-no-repeat bg-cover bg-center justify-between flex flex-row items-center px-5 py-3 gap-2"
          style={{
            boxShadow: "inset 0px -3px 0px #3913BA",
            filter: "drop-shadow(0px 3px 0px #280D82)",
          }}
        >
          <img
            src={logo}
            alt="Logo"
            className="max-w-[30vw] ml-[-1rem] mb-[-1rem] mt-[-1.36rem]"
          />

          <div className="flex flex-row gap-2 ml-[-2rem]">
            <DailyBonusButton />
            <img
              src={britishFlag}
              alt="British flag"
              className="h-9 aspect-[38/37]"
            />
          </div>
        </div>
        <div className="flex flex-row justify-between items-end pt-4 px-5 pb-2 bg-[#07024a]">
          <div className="flex flex-row gap-1.5">
            <IconButton
              icon={settingsIcon}
              onClick={() => navigate("/settings")}
              isSelected={location.pathname === "/settings"}
            />
            <IconButton
              icon={notificationIcon}
              onClick={() => navigate("/notifications")}
              isSelected={location.pathname === "/notifications"}
            />
            <IconButton
              icon={airdropIcon}
              onClick={() => dispatch(setAirdropPopUpOpen(true))}
              isSelected={isAirdropPopUpOpen}
            />
          </div>
          <div className="flex flex-row gap-1.5">
            <BalanceIndicator
              tooltipId={"airdrop-balance-indicator-tooltip"}
              tooltipText="CasinoCoins AirDrop"
              balance={airdropBalance}
              icon={<AirdropCoinIcon className="w-5 h-5 m-2" />}
            />
            <BalanceIndicator
              tooltipId={"chip-balance-indicator-tooltip"}
              tooltipText={
                <div className="flex flex-row items-center">
                  $1 <span className="mx-2">=</span>{" "}
                  <ChipIcon className="w-5 h-5 mr-1.5" /> {dollarWorthInChips}
                </div>
              }
              balance={chipBalance}
              icon={<ChipIcon className="w-5 h-5 m-2" />}
              showPlusSign={true}
              onPlusClick={navigateToDeposits}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeHeader;
