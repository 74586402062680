import Tab from "./Tab";

const TabBar = ({
  leftTitle,
  middleTitle,
  rightTitle,
  leftTabContent,
  middleTabContent,
  rightTabContent,
  selectedTabIndex,
  setSelectedTabIndex,
}) => {
  const tabContents = [leftTabContent, middleTabContent, rightTabContent];
  return (
    <>
      <div
        className="w-full flex flex-row items-center min-h-14 border rounded-2xl p-1.5 mt-1 border bg-transparent"
        style={{
          backgroundColor: "rgba(33, 18, 123, 0.45)",
          borderColor: "rgba(66, 43, 210, 0.30)",
        }}
      >
        <div className="w-1/3">
          <Tab
            title={leftTitle}
            isSelected={selectedTabIndex === 0}
            onClick={() => setSelectedTabIndex(0)}
          />
        </div>
        <div className="w-1/3">
          <Tab
            title={middleTitle}
            isSelected={selectedTabIndex === 1}
            onClick={() => setSelectedTabIndex(1)}
          />
        </div>
        <div className="w-1/3">
          <Tab
            title={rightTitle}
            isSelected={selectedTabIndex === 2}
            onClick={() => setSelectedTabIndex(2)}
          />
        </div>
      </div>
      {tabContents[selectedTabIndex]}
    </>
  );
};

export default TabBar;
