import { configureStore } from '@reduxjs/toolkit'
import { officialApi } from '../services/official';
import userSlice from '../features/userSlice';
import slotsSlice from '../features/slotsSlice';
import diceSlice from '../features/diceSlice';
import generalSlice from '../features/generalSlice';
import withdrawalSlice from '../features/withdrawalSlice';

export default configureStore({
  reducer: {
    [officialApi.reducerPath]: officialApi.reducer,
    user: userSlice,
    slots: slotsSlice,
    dice: diceSlice,
    general: generalSlice,
    withdrawal: withdrawalSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(officialApi.middleware),
});