import spinBarBackground from "../../assets/images/slot_machine/spin_bar_background.png";
import spinButton from "../../assets/images/slot_machine/spin_button.png";
import addFundsButton from "../../assets/images/slot_machine/add_funds_button.png";
import { useSelector } from "react-redux";
import CircleIconButton from "./CircleIconButton";
import plusIcon from "../../assets/images/slot_machine/plus_icon.png";
import minusIcon from "../../assets/images/slot_machine/minus_icon.png";
import TextWithStroke from "./TextWithStroke";
import { useRef, useMemo, useCallback, useEffect } from "react";
import { setBetSize } from "../../features/slotsSlice";
import { useDispatch } from "react-redux";
import { useNavigateToDeposits } from "../../utils/routes";
import { useUserBalance } from "../../utils/user";

const SpinBar = ({ onSpin }) => {
  const dispatch = useDispatch();
  const { isLocked, isSpinning, betSize } = useSelector((state) => state.slots);
  const navigateToDeposits = useNavigateToDeposits();

  const isSpinDisabled = useMemo(() => {
    return isSpinning || isLocked;
  }, [isSpinning, isLocked]);

  const { chipBalance: balance } = useUserBalance();

  const prevOnMainButtonClick = useRef(onSpin);
  const prevMainButtonImage = useRef(spinButton);

  const onMainButtonClick = useMemo(() => {
    if (isSpinning) return prevOnMainButtonClick.current;

    const newOnClick = balance < 10 ? navigateToDeposits : onSpin;
    prevOnMainButtonClick.current = newOnClick;
    return newOnClick;
  }, [balance, navigateToDeposits, onSpin, isSpinning]);

  const mainButtonImage = useMemo(() => {
    if (isSpinning) return prevMainButtonImage.current;

    const newImage = balance < 10 ? addFundsButton : spinButton;
    prevMainButtonImage.current = newImage;
    return newImage;
  }, [balance, isSpinning]);

  const incrementAmount = useMemo(() => {
    const result = Math.floor(balance * 0.01);

    return Math.max(1, result);
  }, [balance]);

  const incrementBetSize = useCallback(() => {
    const newBetSize = Math.min(betSize + incrementAmount, balance);
    dispatch(setBetSize(newBetSize));
  }, [dispatch, betSize, balance, incrementAmount]);

  const decrementBetSize = useCallback(() => {
    const newBetSize = Math.max(
      betSize - incrementAmount,
      Math.floor(betSize * 0.95),
      10
    );
    dispatch(setBetSize(newBetSize));
  }, [dispatch, betSize, incrementAmount]);

  useEffect(() => {
    if (isSpinning) return;

    if (betSize > balance) {
      dispatch(setBetSize(balance));
    }
    if (betSize === null || betSize === undefined) {
      const newBetSize = Math.max(Math.min(balance, incrementAmount), 10);
      dispatch(setBetSize(newBetSize));
    }
  }, [balance, betSize, dispatch, isSpinning, incrementAmount]);

  return (
    <div
      className="w-full rounded-full bg-center bg-no-repeat bg-cover aspect-[2152/454] flex flex-row items-center justify-between pl-5 pr-3"
      style={{
        backgroundImage: `url(${spinBarBackground})`,
      }}
    >
      <div className="flex flex-row gap-2 items-center h-full">
        <CircleIconButton
          alt="Minus"
          gradientColorBottom={"#810909"}
          gradientColorTop={"#DE3A24"}
          icon={minusIcon}
          onClick={decrementBetSize}
          isDisabled={betSize <= 10 || isSpinning}
        />
        <div className="flex flex-col items-center">
          <div className="opacity-50 mb-[-0.3rem]">
            <TextWithStroke
              text="Total Bet"
              strokeColor="#F7D0FF"
              className="text-sm whitespace-nowrap"
            />
          </div>

          <TextWithStroke
            text={betSize?.toString() ?? ""}
            strokeColor="#F7D0FF"
            className="text-2xl"
          />
        </div>
        <CircleIconButton
          alt="Plus"
          gradientColorTop={"#24DE72"}
          gradientColorBottom={"#098111"}
          icon={plusIcon}
          onClick={incrementBetSize}
          isDisabled={betSize === balance || isSpinning}
        />
      </div>
      <button
        onClick={onMainButtonClick}
        className="aspect-[830/318] h-[68%] mt-2.5"
        disabled={isSpinDisabled}
        style={{
          filter: isSpinDisabled ? "grayscale(100%)" : null,
        }}
      >
        <img src={mainButtonImage} />
      </button>
    </div>
  );
};

export default SpinBar;
