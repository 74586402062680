import referralBalanceCardBackground from "../assets/images/referral_balance_card_background.png";
import { useSelector } from "react-redux";
import { useState, useMemo, useCallback, useEffect } from "react";
import {
  useGetReferralStatisticsQuery,
  useGetUnclaimedRewardsQuery,
  useClaimAllCommissionsMutation,
} from "../services/official";
import { useDispatch } from "react-redux";
import { setRewardPopUpStatus } from "../features/generalSlice";

const ReferralBalanceCard = () => {
  const { id: userId } = useSelector((state) => state.user);
  const { data } = useGetReferralStatisticsQuery({ userId });
  const { data: unclaimedRewards } = useGetUnclaimedRewardsQuery({ userId });
  const [claimAllCommissions, result] = useClaimAllCommissionsMutation();
  const [rewardSum, setRewardSum] = useState(null);
  const dispatch = useDispatch();
  const isBonusAvailable = useMemo(() => {
    return (
      unclaimedRewards?.unclaimedRewards?.some(
        (reward) => reward?.type === "Commission"
      ) ?? false
    );
  }, [unclaimedRewards]);

  const onClaimReward = useCallback(async () => {
    const commissionRewards = unclaimedRewards?.unclaimedRewards?.filter(
      (reward) => reward?.type === "Commission"
    );
    let rewardAmount = 0;

    for (const reward of commissionRewards) {
      rewardAmount += reward.rewardAmount;
    }
    setRewardSum(rewardAmount);
    await claimAllCommissions();
  }, [unclaimedRewards, claimAllCommissions]);

  useEffect(() => {
    if ((result.isLoading || result.isSuccess) && rewardSum !== null)
      dispatch(
        setRewardPopUpStatus({
          isOpen: true,
          isLoading: result.isLoading,
          isSuccess: result.isSuccess,
          reward: rewardSum,
          currency: "USD",
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, rewardSum]);

  return (
    <div
      style={{
        overflow: "hidden",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        aspectRatio: "392/225",
        backgroundImage: `url(${referralBalanceCardBackground})`,
      }}
      className="w-full rounded-2xl"
    >
      <div className="flex flex-grow flex-col justify-between items-start gap-2 px-5 pb-5 pt-5 h-full">
        <div className="flex flex-col gap-1 w-full justify-between h-full">
          <div className="flex flex-col">
            <div className="text-sm">Referral balance</div>
            <div className="text-3xl text-[#FCE45F]">
              ${data?.referralBalanceInUsd.toFixed(2)}
            </div>
            <div
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.18)",
              }}
              className="rounded-xl p-2 text-sm w-fit mt-2"
            >
              Claim every $10
            </div>
          </div>
          <button
            className="flex w-full h-10 justify-center items-center rounded-lg"
            style={{
              background: !isBonusAvailable
                ? "linear-gradient(to bottom, #15CF05, #91F934)"
                : "linear-gradient(to bottom, #280EC7, #4734BD)",
              filter: !isBonusAvailable ? "grayscale(1)" : "",
              color: !isBonusAvailable ? "#1E0F6A" : "white",
            }}
            onClick={onClaimReward}
            disabled={!isBonusAvailable}
          >
            <div
              style={{
                opacity: !isBonusAvailable ? 0.35 : 1,
              }}
            >
              Claim
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReferralBalanceCard;
