import { useTonConnectUI } from "@tonconnect/ui-react";
import Button from "../../components/Button";
import ModalPopUp from "../../components/ModalPopUp/ModalPopUp";

const ConfirmWalletDisconnectPopUp = ({ onClose, isOpen }) => {
  const [tonConnectUI] = useTonConnectUI();

  return <ModalPopUp
    isOpen={isOpen}
    close={onClose}
    title="Are you sure?"
    showCloseButton
  >
    <div className="text-center my-8 no-wrap">
      Do you want to disconnect your Telegram Wallet?
    </div>
    <div className="flex flex-row justify-between w-full gap-12">
      <Button
        variant="fifth"
        text="Disconnect"
        onClick={() => {
          tonConnectUI.disconnect();
          onClose();
        }}
      />
      <Button variant="third" text="Cancel" onClick={onClose} />
    </div>
  </ModalPopUp>;
};

export default ConfirmWalletDisconnectPopUp;
