import React, { useRef, useEffect } from "react";
import { useRoll } from "../utils/dice";

// Temporary animations
import diceBackground from "../assets/images/dice/background.png";
import RollButton from "../components/Dice/RollButton";
import DiceSphere from "../components/Dice/DiceSphere";
import ParticularOutcomeSelectionField from "../components/Dice/ParticularOutcomeSelectionField";
import DoubleButton from "../components/Dice/DoubleButton";
import HalfOutcomeSelectionField from "../components/Dice/HalfOutcomeSelectionField";
import BetSizeInput from "../components/Dice/BetSizeInput";
import BalanceIndicator from "../components/Dice/BalanceIndicator";
import { useCountUp } from "react-countup";
import { useSelector } from "react-redux";
import {
  setDisplayedAirDropBalance,
  setDisplayedChipBalance,
  setLocked,
  setRolling,
} from "../features/diceSlice";
import { useDispatch } from "react-redux";
import { useUserBalance } from "../utils/user";
import airdropCoinIcon from "../assets/images/dice/airdrop_coin_icon.png";
import chipIcon from "../assets/images/dice/chip_icon.png";

const DiceScreen = () => {
  const diceRef = useRef();
  const dispatch = useDispatch();
  const { displayedAirDropBalance, displayedChipBalance } = useSelector(
    (state) => state.dice
  );
  const { chipBalance, airdropBalance } = useUserBalance();
  const { update: updateChipCounter } = useCountUp({
    ref: "chip-counter",
    start: displayedChipBalance,
    delay: 0,
    duration: 3,
    decimals: 1,
    prefix: "",
    useEasing: true,
  });

  const { reset: resetAirdropCounter, update: updateAirdropCounter } =
    useCountUp({
      ref: "airdrop-counter",
      start: displayedAirDropBalance,
      delay: 0,
      duration: 3,
      decimals: 1,
      prefix: "",
      useEasing: true,
    });

  const roll = useRoll({ diceRef });

  useEffect(() => {
    dispatch(setLocked(false));
    dispatch(setRolling(false));
    dispatch(setDisplayedAirDropBalance(airdropBalance));
    dispatch(setDisplayedChipBalance(chipBalance));
  }, []);
  return (
    <div
      className="bg-no-repeat bg-cover bg-center mt-[-4rem] py-3 flex flex-col justify-end items-center"
      style={{
        position: "relative",
        width: "100vw",
        height: "100vh",
        cursor: "pointer",
        backgroundImage: `url(${diceBackground})`,
      }}
    >
      <div className="absolute top-0 flex flex-row justify-between w-full px-2 mt-2">
        <BalanceIndicator
          idToPass="chip-counter"
          className="w-10"
          coinIcon={chipIcon}
        />
        <BalanceIndicator
          idToPass="airdrop-counter"
          className="w-8"
          coinIcon={airdropCoinIcon}
        />
      </div>
      <DiceSphere ref={diceRef} />
      <div className="w-full flex flex-col items-center justify-end">
        <BetSizeInput />
        <div className="w-full flex flex-row justify-between items-center px-2 mb-[-1rem]">
          <div className="posterable text-xl aspect-[468/211] h-[12.5vw] flex items-center justify-center text-center">
            x1.9
          </div>
          <div className="posterable text-xl aspect-[468/211] h-[12.5vw] flex items-center justify-center text-center">
            x1.9
          </div>
        </div>

        <div className="w-full h-[12.5vw] flex flex-row justify-between px-2 items-center">
          <HalfOutcomeSelectionField isLow={true} />
          <DoubleButton />
          <HalfOutcomeSelectionField isLow={false} />
        </div>
        <ParticularOutcomeSelectionField />
        <RollButton onClick={roll} />
      </div>
    </div>
  );
};

export default DiceScreen;
