import { useRequestNewDiceGameMutation } from "../services/official";
import { useDispatch, useSelector } from "react-redux";
import { setRolling, setDisplayedAirDropBalance, setDisplayedChipBalance } from "../features/diceSlice";
import store from "../app/store";

function useRoll({ diceRef, updateChipCounter, updateAirdropCounter }) {
    const { betSize } = useSelector((state) => state.slots);
    const [requestDiceGame] = useRequestNewDiceGameMutation();
    const dispatch = useDispatch();

    return async () => {
        if (!diceRef.current) return;

        dispatch(setRolling(true));

        var result = await requestDiceGame({
            betSizeInChips: betSize,
        });
        if (result.error?.status === 400) {
            dispatch(setRolling(false));
            return;
        }
        let currentChipBalance = store.getState().slots.displayedChipBalance;
        dispatch(setDisplayedChipBalance(currentChipBalance - betSize));

        await diceRef.current?.roll(result.data);

        updateAirdropCounter(result.data.airdropPointsReward);
        updateChipCounter(result.data.winningAmountInChips);
        setTimeout(() => dispatch(setRolling(false)), 500);
        currentChipBalance = store.getState().slots.displayedChipBalance;
        const currentAirDropBalance = store.getState().slots.displayedAirDropBalance;

        dispatch(setDisplayedChipBalance(result.data.winningAmountInChips + currentChipBalance));
        dispatch(setDisplayedAirDropBalance(result.data.airdropPointsReward + currentAirDropBalance));
    };
};

export { useRoll };