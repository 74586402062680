import React, { useEffect, useState } from "react";
import Switch from "../components/Switch";
import Card from "../components/Card";
import {
  useGetSettingsQuery,
  useUpdateSettingsMutation,
  useGetUserQuery,
} from "../services/official";
import { useSelector } from "react-redux";
import notificationIcon from "../assets/images/notification_icon.png";
import helpIcon from "../assets/images/help_icon.png";
import fancyRightArrowIcon from "../assets/images/fancy_right_arrow_icon.svg";
import { openLink } from "../utils/socialMedia";

const SettingsScreen = () => {
  const userId = useSelector((state) => state.user.id);
  const { data: user } = useGetUserQuery(userId);
  const { data } = useGetSettingsQuery();
  const [updateSettings] = useUpdateSettingsMutation();
  const [areNotificationsEnabled, setNotificationsEnabled] = useState(false);

  useEffect(() => {
    if (!data) return;
    setNotificationsEnabled(data?.areNotificationsEnabled);
  }, [data?.areNotificationsEnabled]);

  return (
    <div className="mt-24 px-5">
      <div
        className="bg-[#130960] w-full rounded-xl overflow-hidden flex flex-col items-center p-3"
        style={{
          height: "calc(100vh - 17.5rem)",
        }}
      >
        <div className="gradient-text agbalumo-regular text-3xl mb-4">
          Settings
        </div>
        <div className="flex flex-col w-full pt-8 text-xl gap-2">
          <Switch
            icon={
              <img
                src={notificationIcon}
                alt="Notification icon"
                className="w-4"
              />
            }
            text={
              user?.isTelegramUser
                ? "Telegram Notifications"
                : "Email newsletter"
            }
            onClick={() => {
              updateSettings({
                areNotificationsEnabled: !areNotificationsEnabled,
              });
              setNotificationsEnabled(!areNotificationsEnabled);
            }}
            isEnabled={areNotificationsEnabled}
          />
          <Card
            leftText="Help and support"
            icon={<img src={helpIcon} className="w-4" alt="Flag" />}
            rightElement={
              <img
                src={fancyRightArrowIcon}
                className="w-6"
                alt="Right arrow"
              />
            }
            onClick={() => {
              openLink("https://t.me/casino_airdrop_supportbot");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsScreen;
