import dailyBonusBackground from "../assets/images/daily_bonus_background.png";
import {
  useGetDailyLoginStatisticsQuery,
} from "../services/official";
import { useSelector } from "react-redux";
import airdropConfettiIcon from "../assets/images/airdrop_confetti_icon.png";
import { DailyBonusProgressTracker } from "../components/DailyBonusProgressTracker";
import { useMemo } from "react";

const PrizeScreen = () => {
  const { id: userId } = useSelector((state) => state.user);
  const { data } = useGetDailyLoginStatisticsQuery({ userId });
  const currentDay = useMemo(() => data?.daysInARow ?? 0, [data]);
  return (
    <div className="flex flex-col items-center px-5 h-screen w-full relative">
      <img
        className="z-[1] h-[30vh] mt-[10vh]"
        src={airdropConfettiIcon}
        alt="Big airdrop coin confetti icon"
      />
      <div className="z-[5] flex flex-col items-center px-1">
        <DailyBonusProgressTracker currentDay={Math.min(currentDay, 6)} />
        {currentDay >= 6 ? (
          <>
            <div className="mt-10 text-md font-bold">You have collected:</div>
            <div className="text-xl text-[#24D30E] font-bold">All Rewards</div>
            <div className="text-sm text-center opacity-25 mt-10">
              Congratulations!
            </div>
          </>
        ) : (
          <>
            <div className="mt-10 text-md font-bold">Next reward:</div>
            <div className="text-xl text-[#24D30E] font-bold">
              {data?.nextDailyLoginReward ?? 0} Tokens
            </div>
            <div className="text-sm text-center opacity-25 mt-10">
              Come back tomorrow to get your bonus points. Skipping a day resets
              your check-in.
            </div>
          </>
        )}
      </div>
      <div
        className="blur-wrapper absolute bottom-0 left-1/2 transform -translate-x-1/2 rounded-2xl"
        style={{
          width: "calc(100% - 2.5rem)",
        }}
      >
        <div className="h-[88vh] overflow-hidden flex flex-col-reverse blur">
          <div className="absolute w-full h-full bg-black mb-44 rounded-2xl" />
          <img
            src={dailyBonusBackground}
            alt="Daily bonus background"
            className="object-fill overflow-hidden mb-44 rounded-2xl"
            style={{
              WebkitMaskImage:
                "linear-gradient(to bottom, black 40%, transparent 100%)",
              maskImage:
                "linear-gradient(to bottom, black 40%, transparent 100%)",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PrizeScreen;
