
import infoIcon from "../assets/images/info_icon.svg";

const MinimumDepositInfo = ({ minimumAmount, currencySymbol }) => {
  return (
    <div
      className="text-xs text-end mt-0.5 flex items-center"
      style={{ color: "rgba(252, 228, 95, 0.75)" }}
    >
      <img src={infoIcon} className="h-4 inline-block mr-1.5" />
      Minimum deposit {minimumAmount} {currencySymbol}
    </div>
  );
};

export default MinimumDepositInfo;