import ReactModal from "react-modal";
import { ImageButton } from "../ImageButton";
import closeIcon from "../../assets/images/close_icon.svg";
import "./ModalPopUp.css";
import leftPopUpConfetti from "../../assets/images/left_pop_up_confetti.png";
import rightPopUpConfetti from "../../assets/images/right_pop_up_confetti.png";

const ModalPopUp = ({
  isOpen,
  close,
  title,
  children,
  showCloseButton = true,
  showConfetti = false,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      className={{
        base: `pop-up ${title !== undefined ? "pop-up-borders" : ""}`,
        afterOpen: "pop-up-open",
        beforeClose: "pop-up-close",
      }}
      overlayClassName={{
        base: "overlay overlay-pop-up",
        afterOpen: "overlay-open",
        beforeClose: "overlay-close",
      }}
      closeTimeoutMS={500}
    >
      <div className="top-0 fixed left-0 right-0">
        {showConfetti && (
          <div className="absolute w-full flex flex-row justify-between items-start top-0">
            <img
              src={leftPopUpConfetti}
              className="h-14 w-auto"
              alt="Confetti blurred in the background"
            />
            <img
              src={rightPopUpConfetti}
              className="h-14 w-auto"
              alt="Confetti blurred in the background"
            />
          </div>
        )}

        <div className="flex items-center justify-center w-full absolute top-0 left-0 right-0 mt-2">
          <div className="text-3xl agbalumo-regular">{title}</div>
        </div>

        {showCloseButton && (
          <div className="absolute right-0 pt-4 pr-4 top-0">
            <ImageButton
              imageSrc={closeIcon}
              alt="Close icon"
              onClick={close}
              className="w-4 h-4"
            />
          </div>
        )}
      </div>

      <div
        className={`flex-grow flex flex-col justify-start items-center px-6 overflow-y-auto pb-5 ${
          title !== undefined ? "mt-10" : "mt-0"
        }`}
        style={{ maxHeight: `calc(50vh - 4rem)` }}
      >
        {children}
      </div>
    </ReactModal>
  );
};

export default ModalPopUp;
