import doubleButtonImage from "../../assets/images/dice/double_button.png";
import { setBetSize } from "../../features/diceSlice";
import { useSelector, useDispatch } from "react-redux";
import { useMemo, useCallback } from "react";
import { useUserBalance } from "../../utils/user";

const DoubleButton = () => {
  const dispatch = useDispatch();
  const { chipBalance } = useUserBalance();
  const { isLocked, isRolling, betSize } = useSelector((state) => state.dice);

  const isSpinDisabled = useMemo(() => {
    return isRolling || isLocked;
  }, [isRolling, isLocked]);

  const doubleBetSize = useCallback(() => {
    const newBetSize = Math.min(betSize * 2, chipBalance);
    dispatch(setBetSize(newBetSize));
  }, [dispatch, betSize, chipBalance]);

  return (
    <button
      onClick={doubleBetSize}
      className="aspect-[585/245] h-[120%]"
      disabled={isSpinDisabled}
      style={{
        filter: isSpinDisabled ? "grayscale(100%)" : null,
      }}
    >
      <img src={doubleButtonImage} />
    </button>
  );
};

export default DoubleButton;
